import {Container} from "@mui/material";
import {SceneWithSpinningBoxes} from "./components/SpinningBox";

function App() {
    return (
        <div style={{height: '100%', overflow: 'hidden'}}>
            <SceneWithSpinningBoxes/>
        </div>
    );
}

export default App;

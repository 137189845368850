import React, {Suspense, useRef, useState} from 'react'
import {
    Engine, Layer, Model,
    Scene, useBeforeRender,
} from 'react-babylonjs'
import {Vector3, Color3} from '@babylonjs/core'
import "@babylonjs/loaders"
import {Stack} from "@mui/material";

export const SceneWithSpinningBoxes = () => {


    const MyFallback = () => {
        const boxRef = useRef();
        useBeforeRender((scene) => {
            if (boxRef.current) {
                var deltaTimeInMillis = scene.getEngine().getDeltaTime();

                const rpm = 10;
                boxRef.current.rotation.x = Math.PI / 4;
                boxRef.current.rotation.y += ((rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000));
            }
        })

        return <box ref={boxRef} name='fallback' size={2}/>
    }

    return (
        <Stack alignItems={"center"} justifyContent={"center"} sx={{height: '100%'}}>
            <Engine antialias adaptToDeviceRatio canvasId="babylonJS">
                <Scene>
                    <arcRotateCamera
                        name="camera1"
                        target={Vector3.Zero()}
                        alpha={Math.PI / 2}
                        beta={Math.PI / 6}
                        radius={16}
                        lowerRadiusLimit={14}
                        upperRadiusLimit={22}
                    />
                    <hemisphericLight
                        name="light1"
                        intensity={0.8}
                        direction={Vector3.Up()}
                    />
                    <layer name={"background"} imgUrl={"/asset/pizza_bg.jpg"} isBackground={true} />
                    <Suspense fallback={<MyFallback/>}>
                        <Model rootUrl={'./asset/'} sceneFilename={'pizza.babylon'}
                               position={new Vector3(0, 0, 0)}/>
                    </Suspense>
                </Scene>
            </Engine>
        </Stack>
    );

}